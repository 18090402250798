


export const register = /* GraphQL */ `
    mutation SyncupMutation{
    register(input:{email:"$email",pwd:"$pwd",type:"$type"}){
    responseData{ 
code
message
status
errors
}

}

}
`;

export const login = /* GraphQL */ `
    mutation SyncupMutation{
    login(input:{email:"$email",pwd:"$pwd"}){
    responseData{ 
code
message
status
errors
}

}

}
`;

export const forgotPwd = /* GraphQL */ `
    mutation SyncupMutation{
    forgotPwdInit(input:{email:"$email"}){
    responseData{ 
code
message
status
errors
}

}

}
`;

export const forgotPwdConfirm = /* GraphQL */ `
    mutation SyncupMutation{
    forgotPwdConfirm(input:{code:"$code",email:"$email",pwd:"$pwd"}){
    responseData{ 
code
message
status
errors
} 
} 
}
`;

export const registerConfirm = /* GraphQL */ `
   mutation MyMutation {
  registerConfirm(input: {code: "$code", email: "$email"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const registerCodeResend = /* GraphQL */ `
  mutation CodeResendMutation {
  registerCodeResend(input: {email: "$email" }) {
    responseData {
      code
      errors
      message
      status
    }
  }
} 
`;
export const changePwd = /* GraphQL */ `
  mutation ChangePwdMutation {
    changePwd(input: { email: "$email", newPwd: "$newPwd", pwd: "$oldPwd" }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;

export const refreshToken = /* GraphQL */ `
 mutation RTMutation {
    refreshToken(input: { email: "$email", token: "$token"  }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;

export const logout = /* GraphQL */ `
 mutation LogoutMutation  {
    logout (input: { email: "$email", token: "$token", tokenType: "access"  }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;
export const timeZone = /* GraphQL */ `
 mutation TZMutation {
    timezone(input: {  code:"$code"}) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;
export const getExpertProfile = /* GraphQL */ `
 mutation EPLMutation {
    expertPerLoad(input: { sub: "$sub" }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;
export const getClientProfile = `
mutation CPRMutation {
    clientPerLoad(input: {sub: "$sub"}) {
      responseData {
        code
        errors
        message
        status
      }
    }
  }
  `;
export const updateExpertPersonal = ` 
mutation ExpertPerMutation {
        expertPerUpdate(input: {sub: "$sub", email: "$email", userType: $userType, address1: "$address1", address2: "$address2", avatar: "$avatar", browserCountry: "$browserCountry", browserTimezone: "$browserTimezone", city: "$city", countryId: $country, firstname: "$fName", lastname: "$lName", phone: "$phone", photo: "$photo", state: "$state", timezoneId: $timeZone, profilePercentage: $percentage,otherLangs:"$otherLangs"}) {
        responseData {
            code
            errors
            message
            status
        }
        }
    }
`;
export const getExpertEducation = /* GraphQL */ `
 mutation EELMutation {
  expertEduLoad(input: {expertId: $expertId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const getExpertExperience = /* GraphQL */ `
 mutation EELMutation {
  expertExpLoad(input: {expertId:$expertId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const updateExpertEducation = /* GraphQL */ `
mutation EEUMutation {
  expertEduUpdate(input: {[$ID]sub: "$sub", expertId: $expertId, degreeId: $degreeId, email: "$email", endDate: "$endDate", major: "$major", profilePercentage: $profilePercentage, school: "$school", startDate: "$startDate", countryId: $countryId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const updateExpertExperience = /* GraphQL */ `
mutation EExpUpdateMutation {
  expertExpUpdate(input: {[$endDate][$ID]expertId: $expertId,company: "$company", email: "@email", sub: "$sub", current: $current, expYears: $expYears, role: "$role", startDate: "$startDate", profilePercentage: $profilePercentage}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const updateExpertProfessional = /* GraphQL */ `
mutation EPUMutation {
  expertProUpdate(input: {sub: "$sub", userType: $userType, email: "$email", weeklyHrsId: $weeklyHrsId, title: "$title", indTypeIds : "$indTypeIds",skillIds: "$skillIds", resume: "$resume", profilePercentage: $percentage, profile: "$profile", expLevelId: $expLevelId, dailyRate:$dailyRate, compIds: "$compIds",compCatIds:"$compCatIds"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const getExpertProfessional = /* GraphQL */ `
mutation EPLMutation {
  expertProLoad(input: {sub: "$sub"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;



export const loadClientProfile = /* GraphQL */ `

mutation CPRMutation {
    clientPerLoad(input: {sub: "$sub"}) {
      responseData {
        code
        errors
        message
        status
      }
    }
  }

  `;


export const loadClientBusinessProfile = /* GraphQL */ `

  mutation CBLMutation {
    clientBusLoad(input: {sub: "$sub"}) {
      responseData {
        code
        errors
        message
        status
      }
    }
  }
  
  `;
export const getExpertSocial = /* GraphQL */ `
 mutation ESLMutation {
  expertSocLoad(input: {expertId: $expertId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const updateExpertSocial = /* GraphQL */ `
mutation ESUMutation {
  expertSocUpdate(input: {[$ID]email: "$email", expertId: $expertId, linkId: $linkId, profilePercentage: $profilePercentage, sub: "$sub", url: "$url"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const getExpertCertificate = /* GraphQL */ `
 mutation ECLMutation {
    expertCerLoad(input: { expertId: $expertId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;
export const getExpertTestimonial = /* GraphQL */ `
 mutation ECLMutation {
    expertTesLoad(input: { expertId: $expertId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;
export const updateExpertTestimonial = /* GraphQL */ `
 mutation ETUMutation {
  expertTesUpdate(input: {[$ID]email: "$email", expertId: $expertId, fileName: "$fileName", name: "$name", profilePercentage: $profilePercentage, sub: "$sub", url: "$url"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const updateExpertCertificate = /* GraphQL */ `
 mutation ETUMutation {
  expertCerUpdate(input: {[$ID]email: "$email", expertId: $expertId, fileName: "$fileName", name: "$name", profilePercentage: $profilePercentage, sub: "$sub", url: "$url"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const ClientPreviewLoad =  /* GraphQL */ `
  mutation CALMutation {
    clientAllLoad(input: {sub: "$sub"}) {
      responseData {
        code
        errors
        message
        status
      }
    }
  }
  `;

export const ExpertPreviewLoad =  /* GraphQL */ `
  mutation EALMutation {
  expertAllLoad(input: {sub: "$sub"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
  `;
export const ExpertRowDelete =  /* GraphQL:type: (edu/exp/cer/soc/tes)  */ `
mutation ERDMutation {
    expertRowDelete(input: { id: $id, type: "$type" }) {
        responseData {
            code
            errors
            message
            status
        }
    }
} `;

export const ClientJobsLoad = `
mutation MyMutation {
  clientJobsLoad(input: {clientId: $clientId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
} `;
export const ClientJobsUpdate = `mutation MyMutation {
jobUpdate(input: {[$ID] clientId: $clientId,compCatIds:"$compCatIds",rateFrom:$rateFrom,rateTo:$rateTo,type:"$type", email: "$email", title: "$title", desc: "$desc", weeklyHrsId: $weeklyHrsId, statusId: 1, projLengthId: $projLengthId, skillIds: "$skillIds", hourlyRate: $hourlyRate, fixedRate: $fixedRate, expLevelId: $expLevelId, dailyRate: $dailyRate, countryId: $countryId,indTypeIds:"$indTypeIds" ,compIds: "$compIds", city: "$city", state: "$state",statusId :$statusId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
} `;

export const ClientJobsDelete = `
mutation MyMutation {
  jobDelete(input: {clientId: $clientId, id: $id}) {
    responseData {
      code
      errors
      message
      status
    }
  }
} `;


export const clientJobsRead = `
mutation MyMutation {
  jobRead(input: { guid: "$guid"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
} `;
// 100 - New/Draft, 200 - Posted, 300 - Hired, 400 - Closed, 500 - Archived ,
export const clientJobsStatusUpdate = `
mutation MyMutation {
jobStatusUpdate(input: { clientId: $clientId, jobCode: $jobCode, jobId: $id }) {
    responseData {
        code
        errors
        message
        status
    }
}
}`;
export const clientDashboardCounts = `
mutation MyMutation {
    clientDashboardCounts(input: { clientId: $clientId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const searchJobs = `
mutation MyMutation {
  searchJobs(input: {country: $country, expLvlIds: "$expLvlIds", jobTypes: "$jobTypes", keyword: "$keyword", projLengthIds: "$projLengthIds", weeklyHrsIds: "$weeklyHrsIds",skillIds:"$skillIds",complianceCategoryIds:"$complianceCategoryIds",complianceStandardIds:"$complianceStandardIds",dateSince:"$dateSince",industryIds:"$industryIds",rateRangeFrom:"$rateRangeFrom",rateRangeTo:"$rateRangeTo",rateType:"$rateType"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const proposalCreate = `
mutation MyMutation {
  proposalCreate(input: {attachments: "$attachments", bid: $bid, clientId: $clientId, coverLetter: "$coverLetter", email: "$email", expertId: $expertId, jobId: $jobId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const proposalLoadAll = `
mutation MyMutation {
  proposalLoadAll(input: {clientId: $clientId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const searchExpert = `
mutation MyMutation {
  searchTalents(input: {avgRating:"$avgRating",otherLangs:"$otherLangs",country: $country, expLvlIds: "$expLvlIds", keyword: "$keyword" , weeklyHrsIds: "$weeklyHrsIds",skillIds:"$skillIds",complianceCategoryIds:"$complianceCategoryIds",complianceStandardsIds:"$complianceStandardIds",dateSince:"$dateSince",industryIds:"$industryIds",rateRangeFrom:"$rateRangeFrom",rateRangeTo:"$rateRangeTo",rateType:"daily"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const proposalStatusUpdate = `
mutation MyMutation {
    proposalStatusUpdate(input: { id: $id, statusCode: $statusCode }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`; 

export const adminUsers = `
mutation MyMutation {
    adminUsers(input:{fetchType: "$fetchType", userType: $userType}) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const adminfeatureStatusUpdate = `
mutation MyMutation {
    adminStatusUpdate(input: {  status: "$status", statusType: "$statusType",userId: $userId, userType: $userType ,featuredLogo: "$featuredLogo",featuredLinkType : "$featuredLinkType" }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const adminStatusUpdate = `
mutation MyMutation {
    adminStatusUpdate(input: {  status: "$status", statusType: "$statusType", userId: $userId, userType: $userType$reason }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const flagUser = `
mutation MyMutation {
  flagUser(input: {flagId: $flagId, userId: $userId, userType: $userType}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const updateReportStatus = `
mutation MyMutation {
  updateReportStatus(input: {reportId: $reportId, status: "$status"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}` ;

export const adminJobStatusUpdate = `
mutation AdminJobStatusUpdate {
  adminJobStatusUpdate(input: {featured: $featured, id: $id}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}` ;


export const adminCompanyStats = `
mutation CompanyStatsMutation {
  adminCompanyStats(input: {website: "$website"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
} `;

export const adminExpCertStatus = /* GraphQL */ `
mutation AdminExpCertStatusMutation {
  adminUpdateExpCertStatus(input: {certId: $certId, expertId: $expertId, status: $status}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;