import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import validator from 'validator';
import Message from "../../components/Message";
import ConfirmMessages from "../../utils/confirmMessages";
import { getEncodedEmail } from "../../utils/helperFn";
import { signInCall, confirmCode, codeResend } from "../../utils/apiCalls";
const imgLoader = "../assets/image/svg/spinning-circles.svg";
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050;
  }
`;

const ModalSignIn = (props) => {
    const [visibleLoader, setVisibleLoader] = useState(false);

    const [email, setEmail] = useState('');
    const [showPass, setShowPass] = useState(true);
    const gContext = useContext(GlobalContext);
    const [values, setvalues] = React.useState({ email: '', password: '', error: { email: '', password: '' } });

    const [errorMessage, setErrorMessage] = React.useState('');

    const [codeConfirm, setCodeConfirm] = React.useState('');
    const [confirmationValues, setConfirmationValues] = React.useState({ code: '', error: '' });
    const pathName = useLocation().pathname;
    
    const handleClose = () => {
        setErrorMessage('');
        setCodeConfirm('');
        setConfirmationValues({ code: '', error: '' });
        setvalues({ email: '', password: '', error: { email: '', password: '' } });
        gContext.toggleSignInModal();
    };


    const togglePassword = () => {
        setShowPass(!showPass);
    };

    const handleConfirmationChange = (e) => {
        setErrorMessage("");
        const { name, value } = e.target;
        setConfirmationValues({ code: value, error: (value == "") ? "Code is required" : "" });
    }
    async function verifyEmail() {
        // let data = registerCodeResend.replace("$email", email);
        try {
            const result = await codeResend(email);

            if (result.data && result.data.registerCodeResend.responseData) {
                console.log(result.data.registerCodeResend.responseData);
                if (result.data.registerCodeResend.responseData.code == "200") {

                    setCodeConfirm('step2');
                }
                else {
                    setErrorMessage(JSON.parse(result.data.registerCodeResend.responseData.message));
                }
            }
        }
        catch (e) {
            setErrorMessage("Server error");
        }
    }

    async function checkConfirmation() {
        setErrorMessage("");
        if (confirmationValues.code == "") {
            setConfirmationValues({ ...confirmationValues, error: "Confirmation code is required" });
            return;
        }
        // let data = registerConfirm.replace("$email", email).replace("$code", confirmationValues.code.trim());
        try {
            const result = await confirmCode(email, confirmationValues.code.trim());

            if (result.data && result.data.registerConfirm.responseData) {
                console.log(result.data.registerConfirm.responseData);
                if (result.data.registerConfirm.responseData.code == "200") {

                    setCodeConfirm('step3');
                }
                else {
                    setErrorMessage(JSON.parse(result.data.registerConfirm.responseData.message));
                }
            }
        }
        catch (e) {
            setErrorMessage("Server error");
        }

    }
    function checkValidity() {
        let isValid = false;
        let { error, ...rest } = values;

        Object.values(rest).forEach(val => {
            if (val === '') {
                isValid = true;
            }
        });

        error.email = validator.isEmpty(rest.email) ? "Email is required" : error.email;

        error.password = validator.isEmpty(rest.password) ? "Password is required" : error.password;

        setvalues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }
    const handleChange = (e) => {
        setCodeConfirm('');
        const { name, value } = e.target;

        let error = { ...values.error };
        setErrorMessage("");

        switch (name) {

            case "password":
                error.password = validator.isEmpty(value) ? "*" : "";
                break;
            case "email":
                error.email = validator.isEmail(value) ? "" : "Invalid email";
                break;
            default:
                break;
        }

        setvalues({ ...values, error, [name]: value });

    }
    function loginUser() {

        setErrorMessage("");
        if (checkValidity()) {
            gContext.setLoaderModalVisible(true);
            signIn();
        }
    }
    async function signIn() {


        // let data = login.replace("$email", values.email).replace("$pwd", values.password);

        try {
            const result = await signInCall(values.email, values.password);
             

            if (result.data && result.data.login.responseData) {

                if (result.data.login.responseData.code == "200") {
                    let response = JSON.parse(result.data.login.responseData.message);
                    if (response.payload["custom:usertype"] === "3")
                    {
                        localStorage.setItem('connect', JSON.stringify(JSON.parse(result.data.login.responseData.message)));

                        if (pathName == "/") {
                            window.location.href = "/Auth";
                        }
                        else
                            window.location.href = pathName;
                    }
                    else
                        setErrorMessage("Invalid admin user.");
                }
                else {
                    if (result.data.login.responseData.message == '"User is not confirmed."') {
                        setEmail(values.email);
                        setCodeConfirm('step1');

                    }
                    else
                        setErrorMessage(JSON.parse(result.data.login.responseData.message));
                }
            }
        }
        catch (error) {
           
            gContext.setLoaderModalVisible(false);
            console.log(error);
            setErrorMessage("Server error");
        }

        
        gContext.setLoaderModalVisible(false);
    }
    function handleLoginKeypress(e) {
        //it triggers by pressing the enter key 
        if (e.charCode === 13) {
            loginUser();
        }
    };

    return (
        <ModalStyled
            {...props}
            size="lg"
            centered
            show={gContext.signInModalVisible}
            onHide={gContext.toggleSignInModal}
        >
            <Modal.Body className="p-0">
                <button
                    type="button"
                    className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                    onClick={handleClose}
                >
                    <i className="fas fa-times"></i>
                </button>
                <div className="login-modal-main bg-white rounded-8 overflow-hidden">
                    <div className="row no-gutters">
                        <div className="col-lg-5 col-md-6">
                            <div className="pt-10 pb-6 pl-11 pr-12  bg-green-2 h-100 d-flex flex-column dark-mode-texts"
                            >
                                <div className="pb-9">
                                    <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                                        Welcome
                  </h3>
                                    <p className="mb-0 font-size-4 text-white">
                                        Admin
                  </p>
                                </div>
                                <div className="border-top border-default-color-2 mt-auto">
                                    <div className="d-flex mx-n9 pt-6 flex-xs-row flex-column">
                                        <div className="pt-5 px-9">
                                            <h3 className="font-size-7 text-white"> </h3>
                                            <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                                                
                      </p>
                                        </div>
                                        <div className="pt-5 px-9">
                                            <h3 className="font-size-7 text-white"> </h3>
                                            <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                                           
                      </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-6">
                            <div className="bg-white-2 h-100 px-11 pt-11 pb-7">

                                {errorMessage != "" && <><span className="error py-3   font-weight-semibold line-height-reset px-3 bg-red-opacity-2 rounded-5 font-size-4">{errorMessage}</span><br /></>}
                                {codeConfirm === 'step1' && <><span className="py-3 px-3  font-weight-semibold   bg-green-opacity-2 rounded-5 font-size-4">
                                    {ConfirmMessages.ResendSignupConfirmation} <br />  <a className="btn btn-primary btn-medium  rounded-5 mt-3" style={{ cursor: 'pointer' }} onClick={() => { verifyEmail(); }}> Verify Email</a>
                                </span><br /></>}
                                {(codeConfirm === 'step2') && <> <div className="row mt-2">


                                    <Message message={ConfirmMessages.SignupStep1Confirmation.replace("[$email]", getEncodedEmail(email))} />
                                    <div className=" col-12 form-group mb-8 mt-5 ">
                                        <form >

                                            <div className="form-group">

                                                <label
                                                    htmlFor="code"
                                                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                                >
                                                    Confirmation Code <span className="error font-size-3">*</span>
                                                </label>
                                                <input
                                                    type="code" name="code"
                                                    className={`form-control    ${(confirmationValues.error.length > 0) ? 'form-control-error' : ''}`}
                                                    placeholder="Confirmation Code"
                                                    id="code" required onChange={handleConfirmationChange}
                                                />
                                                {confirmationValues.error.length > 1 && (
                                                    <span className="text-red font-size-3">{confirmationValues.error}</span>
                                                )}
                                            </div></form>

                                        <button type="button" className="btn btn-primary btn-medium  rounded-5 text-uppercase" onClick={(e) => {
                                            e.preventDefault();
                                            checkConfirmation();
                                        }}>
                                            <i class="fa fa-sign-in-alt mr-2 pt-1" > </i>   Submit
                                          </button>
                                    </div>



                                </div></>}
                                {(codeConfirm === 'step3') ?

                                    <Message message={ConfirmMessages.SignupStep2Confirmation} /> : ''}
                                {(codeConfirm != 'step2') ? <form>
                                    <div className="form-group">
                                        <label
                                            htmlFor="email"
                                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                        >
                                            E-mail <span className="error font-size-3">*</span>
                                        </label>
                                        <input
                                            name="email"
                                            type="email"
                                            className={`form-control    ${(values.error.email.length > 0) ? 'form-control-error' : ''}`}
                                            placeholder="example@gmail.com"
                                            id="email" required onChange={(e) => (handleChange(e))}
                                        /> {values.error.email.length > 1 && (
                                            <span className="text-red font-size-3">{values.error.email}</span>
                                        )}
                                    </div>

                                    <div className="form-group">
                                        <label
                                            htmlFor="password"
                                            className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                        >
                                            Password <span className="error font-size-3">*</span>
                                        </label>
                                        <div className="position-relative  ">
                                            <fieldset>  
                                            
                                                 <input
                                                type={showPass ? "password" : "text"}
                                                name="password"
                                                className={`form-control font-weight-normal ${(values.error.password.length > 0) ? 'form-control-error ' : ''}   `}
                                                id="password"
                                                placeholder="Enter password" required onChange={(e) => (handleChange(e))} onKeyPress={(e) => (handleLoginKeypress(e))}
                                            />
                                                <a
                                                    href="/#"
                                                    className=" pos-abs-cr fas mr-6 text-black-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        togglePassword();
                                                    }}
                                                >
                                                    {showPass ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash " ></i>}
                                                    <span className="d-none">none</span>
                                                </a></fieldset>
                                        </div>
                                        {values.error.password.length > 1 && (
                                            <span className="error font-size-3">{values.error.password}</span>
                                        )}
                                    </div>


                                    <div className="form-group d-flex flex-wrap justify-content-between">

                                       
                                    </div>
                                    {/* <!--    <div className="form-group mb-8 text-center">
                                      <ReCaptcha ref={captchaRef} sitekey={siteKey} />
                                      {captchaMessage ===true && (
                                          <span className="error font-size-3">Select captcha</span>
                                      )}
                                  </div>--> */}
                                    <div className="form-group mb-8 position-relative">

                                        <button type="button"  onClick={loginUser} className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">
                                            Log in{" "}
                                        </button>
                                        

                                    </div>
                                    <p className="font-size-4 text-center heading-default-color">
                                       
                                    </p>
                                </form> : ""}
                                <div className="or-devider">
                                    <span className="font-size-3 line-height-reset ">Or</span>
                                </div>
                                <div className="row ">
                                    <div className="col-12 col-xs-12 pt-5   ml-lg-1   ">
                                        <a
                                            href="/#"
                                            className="font-size-4 font-weight-semibold position-relative text-white bg-allports h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4  "                                             >
                                            <i className="fab fa-linkedin pos-abs-cl font-size-6 ml-xs-4 px-5"></i>{" "}
                                            <span>
                                                Log in with  LinkedIn
                                                  </span>
                                        </a>
                                    </div>
                                    <div className="col-12 col-xs-12   ml-lg-1   ">
                                        <a
                                            href="/#"
                                            className="font-size-4 font-weight-semibold position-relative text-white bg-poppy h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4  "
                                        >
                                            <i className="fab fa-google pos-abs-cl font-size-6 ml-xs-4  px-5"></i>{" "}
                                            <span >
                                                Log in  with Google
                      </span>
                                        </a>
                                    </div>
                                    <div className="col-12 col-xs-12   ml-lg-1    ">
                                        <a
                                            href="/#"
                                            className="font-size-4 font-weight-semibold position-relative text-white bg-marino h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4 "
                                        >
                                            <i className="fab fa-facebook-square pos-abs-cl font-size-6 ml-xs-4  px-5"></i>{" "}
                                            <span >
                                                Log in with Facebook
                      </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </ModalStyled>
    );
};

export default ModalSignIn;
