const ConfirmMessages = {
    SignupStep1Confirmation: "Congratulation! A confirmation code has been sent to your [$email] mailbox. Please check your email and enter the verification code to activate your account.",
    SignupStep2Confirmation: "Registration Successful! Please click below button to Login with your SyncupPro account.",
    ResendSignupConfirmation: "Your account is already registered with us. Please click the button below to verify your email address. ",
    ForgotPwdResetCodeConfirmation: "We've sent an email to [$email]. Check your email and enter OTP to reset your password. If you don't see the email, check other places it might be, like spam, social, or other folders.",
    ForgotPwdConfirmation: "Your password has been updated successfully. Please click below button to Login with your SyncupPro account.",
    ChangePwdConfirmation: "Your password has been changed successfully. Please click below button to Login.",
    ProfileConfirmation: "Your profile information updated successfully.",
    
}
export default ConfirmMessages; 